import request from '@/utils/request'
//获取课程章节列表
export function getoutlinelist(params) {
    return request({
        url: '/portal/outline/get-list',
        method: 'GET',
        params
    })
}

//更新章节资料观看进度
export function currentProgress(data) {
  return request({
    url: '/portal/outline/update-study-progress',
    method: 'post',
    data
  })
}